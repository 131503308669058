@import "../../theme.scss";

.datetime {
  height: 56px;
  padding: 16px 24px;
  border-radius: 12px;
  margin: 2px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    width: 100%;
    border: none;
    outline: none;
    @extend .headings_input_text;
    ::-webkit-input-placeholder {
      @extend .headings_input_placeholder;
    }
    :-ms-input-placeholder {
      @extend .headings_input_placeholder;
    }
    ::placeholder {
      @extend .headings_input_placeholder;
    }
  }

  .react-datepicker {
    &__day {
      &--selected {
        background-color: $orange;
        color: white !important;
      }
      &--name {
        color: white !important;
      }
      &-name {
        color: white;
      }
      &--today {
        color: $orange;
      }
      // &--selected,&--today {
      // }
      &--keyboard-selected {
        background-color: $dark-blue;
      }
    }
    &__time {
      &-list-item--selected {
        background-color: $orange !important;
      }
    }

    &-time__header {
      color: white;
    }

    &__current-month {
      color: white;
    }
    &-popper {
      * {
        font-family: $poppins !important;
      }
      &[data-placement^="bottom"] .react-datepicker__triangle::after {
        border-bottom-color: $orange;
      }
    }
    &__navigation-icon::before {
      border-color: white;
    }
    &__header {
      background-color: $orange;
      color: white !important;
    }
  }

  img {
    width: 24px;
  }
}
