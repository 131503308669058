@import "../../theme.scss";

.faq {
  min-height: 678px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 83px;

  @media only screen and (max-width: $width-1024) {
    padding: 80px 33px;
  }

  @media only screen and (max-width: $width-768) {
    padding: 50px 16px;
  }

  .primary {
    @extend .headings_primary;
    margin-bottom: 18px;
    @media only screen and (max-width: $width-768) {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
  .secondary {
    @extend .headings_secondary;
    margin-bottom: 60px;
    @media only screen and (max-width: $width-768) {
      font-size: 16px;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  .row {
    display: flex;
    justify-content: stretch;
    align-items: center;
    & > div:not(:last-child) {
      margin-right: 30px;
      @media only screen and (max-width: $width-768) {
        margin-right: 0px;
        margin-bottom: 16px;
      }
    }
  }

  .column {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .points {
    .row {
      align-items: stretch;
      margin-bottom: 24px;
      @media only screen and (max-width: $width-768) {
        flex-direction: column;
        margin-bottom: 16px;
      }
    }
    .column {
      align-items: stretch;
    }

    .point {
      display: flex;
      position: relative;
      flex-direction: column;
      max-width: 590px;
      background-color: #fff;
      flex :1;
      .expanded {
        padding: 16px;
        position: absolute;
        background-color: #fff;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        border: solid 1px $dark-blue;
        @media only screen and (max-width: $width-768) {
          border-radius: 12px;
          border: solid 1px #363562;
        }
      }
      .collapse {
        padding: 16px;
        height: 100%;
        width: 100%;
        border: solid 1px $dark-blue;
        @media only screen and (max-width: $width-768) {
          border-radius: 12px;
          border: solid 1px #363562;
        }
      }
      .question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &_label {
          font-family: $poppins;
          font-size: 16px;
          font-weight: 500;
          color: #363562;
          @media only screen and (max-width: $width-768) {
            font-weight: 600;
          }
        }
      }
      .answer {
        margin-top: 26px;
        font-family: $poppins;
        font-size: 14px;
        line-height: 1.5;
        color: #565579;
      }
    }
  }
}

.downArrow {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  &_icon {
    width: 50%;
    height: 50%;
    border-color: $dark-blue;
    border-style: solid;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    &_up {
      position: absolute;
      border-width: 2.8px 2.8px 0px 0;
      top: 40%;
    }
    &_down {
      position: absolute;
      border-width: 0px 0px 2.8px 2.8px;
      top: 13%;
    }
  }
}
