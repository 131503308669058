@import "../../theme.scss";

.help {
  overflow: hidden;
  min-height: 768px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media only screen and (max-width: $width-1024) {
    flex-direction: column;
    padding: 40px 30px;
  }
  @media only screen and (max-width: $width-768) {
    padding: 40px 16px;
  }


  .back {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: 167px;
    background-color: $yellow;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    z-index: -1;
    @media only screen and (max-width: $width-1024) {
      margin-top: 167px;
      margin-left: 0px;
    }
    @media only screen and (max-width: $width-768) {
      border-bottom-left-radius: 50px;
      border-top-right-radius: 50px;
    }
  }

  .image-container {
    flex: 1;
    height: 538px;
    max-width: 633px;
    background-color: $dark-blue;
    border-bottom-left-radius: 100px;
    border-top-right-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    img {
      width: 85%;
    }
    @media only screen and (max-width: $width-1024) {
      flex: none;
      height: 338px;
      width: 400px;
      margin-bottom: 30px;
    }
    @media only screen and (max-width: $width-768) {
      width: 100%;
      height: 291px;
      width: 343px;
      border-bottom-left-radius: 50px;
      border-top-right-radius: 50px;
    }
    @media only screen and (max-width: 375px) {
      width: 300px;
    }
  }

  .offer-container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0px 84px;
    align-items: center;
    @media only screen and (max-width: $width-1024) {
      padding: 0px;
    }
    @media only screen and (max-width: $width-768) {
      button {
        width: 100%;
      }
    }
    & > div {
      max-width: 551px;
    }
    .primary {
      @extend .headings_primary;
      margin-bottom: 18px;
      @media only screen and (max-width: $width-1024) {
        margin-bottom: 10px;
      }
    }
    .secondary {
      @extend .headings_secondary;
      margin-bottom: 30px;
      @media only screen and (max-width: $width-1024) {
        margin-bottom: 20px;
      }
    }
  }

  .list {
    margin-bottom: 60px;
    & > div:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .row {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }
  .column {
    display: flex;
    align-items: center;
    flex: 1;
    .image {
      margin-right: 16px;
    }
  }
}
