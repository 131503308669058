@import "../../theme.scss";

.circle-heading {
  position: relative;
  .circle {
    height: 63px;
    width: 63px;
    background-color: $orange;
    position: absolute;
    z-index: -1;
    left: -15px;
    top: -6px;
    border-radius: 100%;
    @media only screen and (max-width: $width-768) {
      width: 50px;
      height: 50px;
      top: -10px;
    }
  }
  @media only screen and (max-width: $width-768) {
    margin-left: 14px;
  }
}
