@import "../../theme.scss";

.textInput {
  position: relative;
  height: 56px;
  padding: 16px 24px;
  border-radius: 12px;
  margin: 2px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  .error {
    border-color: #e80c0c;
  }
  input {
    width: 100%;
    border: none;
    outline: none;
    @extend .headings_input_text;
    ::-webkit-input-placeholder {
      @extend .headings_input_placeholder;
    }
    :-ms-input-placeholder {
      @extend .headings_input_placeholder;
    }
    ::placeholder {
      @extend .headings_input_placeholder;
    }
  }
  &-tooltip {
    position: absolute;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white !important;
    min-width: 20px;
    top: 90%;
    left: 2%;
    right: 10%;
    border-radius: 10px;
    background: #e80c0c;
    z-index: 4;
    &::after {
      content: "";
      position: absolute;
      top: -16px;
      left: 10%;
      margin-left: 0px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent #e80c0c transparent;
    }
  }
}
