@import "../../theme.scss";

.courses {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: $background-color;
  border-bottom-left-radius: 100px;
  padding: 0 83px;
  padding-top: 68px;

  @media only screen and (max-width: $width-1024) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media only screen and (max-width: $width-768) {
    padding-left: 16px;
    padding-right: 16px;
    border-bottom-left-radius: 50px;
  }
  .primary {
    @extend .headings_primary;
    margin-bottom: 18px;
  }
  .secondary {
    @extend .headings_secondary;
    margin-bottom: 60px;
    @media only screen and (max-width: $width-768) {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .row {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  .column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    .button {
      width: 64px;
      height: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      font-size: 24px;
    }
  }

  .courses-wrapper {
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    overflow-x: scroll;
    box-sizing: content-box;

    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .row {
      > div:not(:last-child) {
        margin-right: 40px;
        @media only screen and (max-width: $width-768) {
          margin-right: 16px;
        }
      }
    }
    > .row:not(:last-child) {
      margin-bottom: 40px;
    }

    .course {
      background-color: transparent;
      width: 270px;
      height: 300px;
      perspective: 1000px;

      @media only screen and (max-width: $width-1024) {
        width: 200px;
        height: 230px;
      }

      @media only screen and (max-width: $width-768) {
        width: 162px;
        height: 174px;
      }

      &_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: stretch;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
      }

      &:hover .course_inner {
        transform: rotateY(180deg);
      }

      &_front {
        position: absolute;
        width: 100%;
        height: 100%;
        border: solid 1px #ffebcb;
        border-radius: 12px;
        background-color: #fff;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: $width-768) {
          img {
            width: 65px;
          }
        }

        .label {
          font-family: $poppins;
          font-size: 22px;
          font-weight: 500;
          text-align: center;
          color: #363562;
          @media only screen and (max-width: $width-1024) {
            font-size: 16px;
          }
          @media only screen and (max-width: $width-768) {
            font-size: 14px;
          }
        }
      }

      &_back {
        border-radius: 12px;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-color: $dark-blue;
        padding: 16px;
        transform: rotateY(180deg);

        & > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 12px;
            @media only screen and (max-width: $width-1024) {
              margin-bottom: 8px;
            }
            @media only screen and (max-width: $width-768) {
              margin-bottom: 4px;
            }
          }
        }
        .oval {
          width: 16px;
          height: 16px;
          min-width: 16px;
          margin-right: 12px;
          background-color: #ff6300;
          border-radius: 100%;
          @media only screen and (max-width: $width-768) {
            width: 10px;
            height: 10px;
            min-width: 10px;
            margin-right: 5px;
          }
        }
        .label {
          text-align: left;
          font-family: $poppins;
          font-size: 16px;
          font-weight: 500;
          color: #ffebcb;
          @media only screen and (max-width: $width-1024) {
            font-size: 14px;
          }
          @media only screen and (max-width: $width-768) {
            font-size: 10px;
          }
        }
      }
    }
  }

  
  .universities-wrapper {
    position: relative;
    bottom: -83px;
    width: 100%;
    padding: 60px;
    border-radius: 60px;
    background-color: #ffebcb;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: $width-1024) {
      padding: 40px;
    }

    @media only screen and (max-width: $width-768) {
      padding: 40px 28px;
      border-radius: 40px;
    }

    .universities {
      overflow: hidden;
      overflow-x: scroll;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      
      > .row:last-child {
        margin-bottom: 30px;
      }
      img {
        width: 160px;
      }

      .row {
        margin-bottom: 20px;
        .column {
          justify-content: center;
        }
        > div:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
  }
}
