@import "../../theme.scss";

.footer {
  overflow: hidden;
  min-height: 384px;
  display: flex;
  flex-direction: column;
  padding: 0 83px;
  padding-top: 60px;
  padding-bottom: 15px;

  @media only screen and (max-width: $width-1024) {
    padding: 0 33px;
    padding-top: 50px;
  }

  @media only screen and (max-width: $width-768) {
    padding: 0 16px;
    padding-top: 30px;
  }

  &_upper_web {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media only screen and (max-width: $width-1024) {
      flex-direction: column;
      align-items: stretch;
    }
    @media only screen and (max-width: $width-768) {
      display: none;
    }
    & > div {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
  }

  &_upper_mobile {
    @media only screen and (min-width: 768px) {
      display: none;
    }
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
    }
    .logo {
      margin-bottom: 36px;
    }
    .contact-wrapper {
      justify-content: space-between;
      .navigation {
        width: auto;
      }
      .details {
        flex: 0
      }
    }
  }

  .logo {
    flex: 1;
    img {
      height: 50px;
      margin-bottom: 15px;
      @media only screen and (max-width: $width-768) {
        height: 32px;
        margin-bottom: 0px;
        margin-right: 16px;
        margin-top: 5px;
      }
    }
    .heading {
      width: 275px;
      font-family: $poppins;
      font-size: 16px;
      line-height: 1.5;
      color: $dark-blue;
      @media only screen and (max-width: $width-768) {
        font-size: 14px;
      }
    }
  }
  .navigation {
    width: 150px;
    margin-right: 49px;
    @media only screen and (max-width: $width-768) {
      margin-right: 10px;
    }
    .heading {
      font-family: $poppins;
      font-size: 18px;
      font-weight: bold;
      color: $dark-blue;
      margin-bottom: 18px;
      color: #3d3d3d;
      @media only screen and (max-width: $width-768) {
        font-size: 16px;
      }
    }
    .subheading {
      height: 24px;
      font-family: $poppins;
      font-size: 16px;
      line-height: 1.5;
      color: $dark-blue;
      margin-bottom: 11px;
      cursor: pointer;
      @media only screen and (max-width: $width-768) {
        font-size: 14px;
      }
    }
  }
  .details {
    flex: 1;
    .heading {
      font-family: $poppins;
      font-size: 18px;
      font-weight: bold;
      color: $dark-blue;
      margin-bottom: 18px;
      color: #3d3d3d;
      @media only screen and (max-width: $width-768) {
        font-size: 16px;
      }
    }
    .subheading {
      height: 24px;
      font-family: $poppins;
      font-size: 16px;
      line-height: 1.5;
      color: $dark-blue;
      margin-bottom: 11px;
      @media only screen and (max-width: $width-768) {
        font-size: 14px;
      }
    }
  }
  .contact {
    display: flex;
    & > div:not(:last-child) {
      margin-right: 12px;
    }
    @media only screen and (max-width: $width-768) {
      margin: 30px;
    }
    .image-container {
      position: relative;
      height: 44px;
      width: 44px;
      overflow: hidden;
      img {
        position: absolute;
        left: -53%;
        top: -10%;
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    border: solid 1px #ececec;
    margin: 10px 0;
  }

  &_lower {
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $width-768) {
      justify-content: center;
    }
    .copyright {
      font-family: $poppins;
      font-size: 16px;
      color: $dark-blue;
      @media only screen and (max-width: $width-768) {
        text-align: center;
        font-size: 14px;
      }
    }
    @media only screen and (max-width: $width-768) {
      img {
        display: none;
      }
    }
  }
}
