@import "../../theme.scss";
.header {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 2;

  background-color: transparent;
  height: 92px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 94px;

  @media only screen and (max-width: $width-1024) {
    padding: 0 30px;
  }

  @media only screen and (max-width: $width-768) {
    padding: 0 16px;
    top: 76px;
  }

  .logo {
    flex: 1;
    img {
      width: 160px;
      @media only screen and (max-width: 720px) {
        width: 130px;
      }
    }
  }

  .sections {
    &_label {
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        cursor: pointer;
      }
      & > div:not(:last-child) {
        margin-right: 59px;
        @media only screen and (max-width: 900px) {
          margin-right: 21px;
        }
      }

    }
    &_img {
      display: none;
      img {
        width: 18px;
      }
    }

    @media only screen and (max-width: $width-768) {
      &_label {
        display: none;
      }
      &_img {
        display: block;
      }
    }
  }

  .isSelected {
    color: $orange;
    font-family: $poppins-bold;
  }
  .isHover {
    color: $orange;
  }
}
.navigation-drawer {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $yellow;
  z-index: 3;
  padding: 0 16px;
  font-size: 24px;
  display: none;
  @media only screen and (max-width: $width-768) {
    display: block;
  }
  .right-design {
    width: 35%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: $background-color;
    max-width: 394px;
  }
  .sections {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 102px;
    margin-top: 30px;
    .isSelected {
      color: $orange;
      font-family: $poppins-bold;
    }
    .isHover {
      color: $orange;
    }
    > div {
      z-index: 2;
      margin-bottom: 10px;
    }
  }
}

.header_banner {
  height: 50px;
  width: 100%;
  background: $dark-blue;
  display: flex;
  padding: 10px 0px;
  justify-content: center;

  @media only screen and (max-width: $width-1024) {
    padding: 10px 30px;
    height: 70px;
  }

  @media only screen and (max-width: $width-768) {
    padding: 0px 16px;
    height: 100px;
    flex-direction: column;
  }

  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    img {
      margin-right: 5px;
    }
  }
  &_heading {
    display: flex;
    align-items: center;
    &-main {
      opacity: 0.8;
      font-family: $poppins;
      font-size: 16px;
      line-height: 2;
      // color: $blue;
      color: #fff;
      margin-right: 5px;
      @media only screen and (max-width: 850px) {
        font-size: 17px;
      }
      @media only screen and (max-width: 700px) {
        font-size: 16px;
      }
    }
    &-desc {
      opacity: 0.8;
      font-family: $poppins;
      font-size: 16px;
      line-height: 2;
      // color: $blue;
      color: #fff;
      @media only screen and (max-width: 850px) {
        font-size: 17px;
      }
      @media only screen and (max-width: 700px) {
        font-size: 16px;
      }
    }
  }

  &_section {
    display: flex;
    align-items: center;
    @media only screen and (max-width: $width-1024) {
      display: block;
    }
  }

  &_sub_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 5px ;
    @media only screen and (max-width: $width-1024) {
      padding: 0px;
    }
  }

  .header_country_flag {
    height: 25px;
    width: 25px;
  }
}