@import "../../theme.scss";

.main {
  position: relative;
  padding: 110px 61px 0px 83px;
  background-color: $yellow;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: $width-1024) {
    padding: 0px 30px;
    padding-top: 80px;
  }

  @media only screen and (max-width: $width-768) {
    padding: 0px 16px;
    padding-top: 80px;
    flex-direction: column;
  }

  .right-design {
    width: 35%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: $background-color;
    border-bottom-right-radius: 100px;
    max-width: 394px;

    @media only screen and (max-width: $width-768) {
      z-index: 0;
    }
  }

  .headings {
    z-index: 2;
    position: relative;
    max-width: 600px;
    padding-top: 83px;
    padding-bottom: 83px;

    @media only screen and (max-width: 1200px) {
      max-width: 500px;
    }

    @media only screen and (max-width: 800px) {
      max-width: 400px;
    }

    @media only screen and (max-width: $width-1024) {
      padding-top: 30px;
    }

    @media only screen and (max-width: $width-768) {
      max-width: none;
      padding-top: 0px;
      padding-bottom: 16px;
    }

    .tertiary {
      @extend .headings_heading_2;
      margin-bottom: 2px;
    }
    .primary {
      @extend .headings_heading_1;
      &_img {
        margin-bottom: 30px;
        @media only screen and (max-width: 700px) {
          margin-bottom: 10px;
          img {
            width: 157px;
          }
        }
      }
    }
    .secondary {
      @extend .headings_secondary;
      margin-bottom: 41px;
      @media only screen and (max-width: 700px) {
        margin-bottom: 10px;
      }
    }
  }

  .imageContainer {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    img {
      max-width: 633px;
      @media only screen and (max-width: 1400px) {
        width: 533px;
      }
      @media only screen and (max-width: 1300px) {
        width: 450px;
      }
      @media only screen and (max-width: 1200px) {
        width: 400px;
      }
      // @media only screen and (max-width: 1050px) {
      //   width: 353px;
      // }
      @media only screen and (max-width: $width-1024) {
        width: 300px;
      }
    }
  }
}
