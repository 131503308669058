@import "../../theme.scss";

.button {
  display: inline-block;
  padding: 16px 59px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 20px 35px 0 rgba(255, 99, 0, 0.15);
  background-color: $orange;
  border: none;
  cursor: pointer;
  @extend .headings_button
}
