@font-face {
    font-family: "Poppins";
    src: url("../font/Poppins/Poppins-Regular.ttf") format("woff2"), url("../font/Poppins/Poppins-Regular.ttf") format("font-woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../font/Poppins/Poppins-Bold.ttf") format("woff2"), url("../font/Poppins/Poppins-Bold.ttf") format("font-woff");
    font-weight: normal;
    font-style: normal;
}


