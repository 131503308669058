@import "../../theme.scss";

.chat-with-us {
  cursor: pointer;
  background: $blue;
  color: $white;
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: auto;
  height: auto;
  z-index: 10;
  padding: 20px 30px;
  border-radius: 50px;
  @extend .headings_button;
  display: flex;
  justify-content: center;
  align-items: center;
  > :not(:last-child) {
      margin-right: 10px;
  }
  @media only screen and (max-width: $width-768) {
    padding: 10px;
    > :first-child {
      margin-right: 0px;
    }
    > :last-child {
      display: none;
    }
  }
}
