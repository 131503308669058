@import "../../theme.scss";

.radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2px;
  .children {
    font-family: $poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    &--selected {
      color: #ff6300;
    }
  }
  .checkbox {
    width: 18px;
    height: 18px;
    margin: 0 7px 0 0;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    &--selected {
      color: $dark-blue;
      background-color: $orange;
    }
    &--unselected {
      border: 2px solid #fff;
    }
  }
}
