$yellow: #ffebcb;
$orange: #ff6300;
$dark-blue: #363562;
$grey: #999aaf;
$light-grey: #edece7;
$blue: #565579;
$white: #fff;
$background-color:#fbfdff;

$poppins: "Poppins";
$poppins-bold: "PoppinsBold";
$poppins-semi-bold:"PoppinsSemiBold";

$width-768: 767px;
$width-1024: 1023px;
