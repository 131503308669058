@import "theme/variables";
@import "theme/font";

.headings {
  &_primary {
    font-family: $poppins-bold;
    font-size: 40px;
    color: $dark-blue;
    @media only screen and (max-width: 850px) {
      font-size: 30px;
    }
  }
  &_secondary {
    opacity: 0.8;
    font-family: $poppins;
    font-size: 18px;
    line-height: 2;
    color: $blue;
    @media only screen and (max-width: 850px) {
      font-size: 17px;
    }
    @media only screen and (max-width: 700px) {
      font-size: 16px;
    }
  }
  &_button {
    font-family: $poppins-bold;
    font-size: 18px;
    color: $white;
    @media only screen and (max-width: 700px) {
      font-size: 16px;
    }
  }
  &_heading_1 {
    @extend .headings_primary;
    font-size: 60px;
    line-height: 1.33;
    @media only screen and (max-width: 850px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 720px) {
      font-size: 30px;
    }
  }
  &_heading_2 {
    @extend .headings_secondary;
    opacity: 1;
    color: $orange
  }
  &_input_placeholder {
    font-family: $poppins;
    font-size: 16px;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999aaf;
  }
  &_input_text {
    font-family: $poppins;
    font-size: 16px !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #484770 !important;
    font-weight: 500;
  }
}
