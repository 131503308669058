@import "../../theme.scss";

.tutorials {
  overflow: hidden;
  height: 768px;
  padding: 0px 98px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (max-width: $width-1024) {
    padding: 0px 30px;
    height: 668px;
  }

  @media only screen and (max-width: $width-768) {
    padding: 0px 16px;
    flex-direction: column-reverse;
    justify-content: center;
    height: 830px;
  }


  .headings {
    max-width: 551px;
    @media only screen and (max-width: 1300px) {
      max-width: 400px;
    }
    @media only screen and (max-width: 1200px) {
      max-width: 300px;
    }
    @media only screen and (max-width: $width-768) {
      max-width: none;
      padding-bottom: 40px;
      button {
        width: 100%;
      }
    }

    .circle {
      background-color: $orange;
      opacity: 0.5;
    }
    .primary {
      font-family: $poppins-bold;
      font-size: 40px;
      color: $dark-blue;
      margin-bottom: 18px;
      @media only screen and (max-width: $width-1024) {
        font-size: 30px;
      }
      @media only screen and (max-width: $width-768) {
        font-size: 24px;
      }
    }
    .secondary {
      font-family: $poppins;
      font-size: 18px;
      line-height: 2;
      color: #565579;
      margin-bottom: 50px;
      @media only screen and (max-width: $width-1024) {
        font-size: 16px;
      }
    }
  }

  .bubbles {
    position: relative;
    flex: 1;
    overflow: hidden;
    height: 700px;
    width: 500px;

    @media only screen and (max-width: $width-1024) {
      height: 600px;
    }
    @media only screen and (max-width: $width-768) {
      max-height: 370px;
      width: 330px;
    }
    @media only screen and (max-width: 320px) {
      width: 290px;
    }

    & > div {
      position: absolute;
      right: 0;
      border-radius: 100%;
      overflow: hidden;
      & > div {
        height: 100%;
      }
    }
    &_1 {
      width: 299px;
      height: 299px;
      transform: translateY(-50%);
      top: 40%;
      right: 242px !important;
      opacity: 0.6;
      background-color: #fdc56c;
    }
    &_2 {
      width: 326px;
      height: 326px;
      transform: translateY(-50%);
      top: 35%;
      opacity: 0.5;
      background-color: #ff6300;
    }
    &_3 {
      width: 299px;
      height: 299px;
      transform: translateY(-50%);
      top: 67%;
      right: 116px !important;
      opacity: 0.5;
      background-color: $dark-blue;
    }

    @media only screen and (max-width: 1300px) {
      &_1 {
        width: 250px;
        height: 250px;
      }
      &_2 {
        width: 300px;
        height: 300px;
      }
      &_3 {
        width: 250px;
        height: 250px;
      }
    }

    @media only screen and (max-width: $width-1024) {
      &_1 {
        width: 188px;
        height: 188px;
        transform: translateY(-50%);
        top: 40%;
        right: 170px !important;
      }
      &_2 {
        width: 200px;
        height: 200px;
        transform: translateY(-50%);
        top: 35%;
      }
      &_3 {
        width: 188px;
        height: 188px;
        transform: translateY(-50%);
        top: 60%;
        right: 66px !important;
      }
    }
    @media only screen and (max-width: $width-768) {
      &_1 {
        right: auto !important;
        top: 35%;
        left: 30%;
        transform: translate(-50%, -50%);
      }
      &_2 {
        right: auto !important;
        top: 30%;
        left: 70%;
        transform: translate(-50%, -50%);
      }
      &_3 {
        right: auto !important;
        top: 68%;
        left: 55%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .bubble {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .count {
      font-family: $poppins;
      font-size: 58px;
      text-align: center;
      color: #fff;
      margin-bottom: 8px;
      @media only screen and (max-width: $width-1024) {
        font-size: 38px;
      }
    }
    .label {
      font-family: $poppins;
      font-size: 24px;
      text-align: center;
      color: #fff;
      @media only screen and (max-width: $width-1024) {
        font-size: 16px;
      }
    }
  }
}
