@import "../../theme.scss";

.testimonials {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 75px 83px;

  @media only screen and (max-width: $width-1024) {
    padding: 75px 30px;
  }
  @media only screen and (max-width: $width-768) {
    padding: 40px 16px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    @media only screen and (max-width: $width-768) {
      margin-bottom: 0px;
      align-items: flex-start;
      .secondary {
        display: none;
      }
    }
  }
  .primary {
    @extend .headings_primary;
    margin-bottom: 18px;
    @media only screen and (max-width: $width-768) {
      font-size: 24px;
    }
  }

  .secondary {
    @extend .headings_secondary;
    opacity: 1;
    margin-bottom: 30px;
  }

  & > .secondary {
    display: none;
    @media only screen and (max-width: $width-768) {
      display: block;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $width-768) {
      align-items: flex-start;
    }
    & > div:not(:last-child) {
      margin-right: 24px;
      @media only screen and (max-width: $width-768) {
        margin-right: 15px;
      }
    }
  }

  .button {
    font-size: 40px;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 10px;
    @media only screen and (max-width: $width-768) {
      width: 41px;
      height: 41px;
      font-size: 18px;
    }
  }

  .cards {
    display: flex;
    justify-content: flex-start;
    overflow-y: hidden;
    align-items: center;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
    & > div:not(:last-child) {
      margin-right: 45px;
    }
    .card {
      min-width: 370px;
      max-width: 370px;
      height: 419px;
      background-color: $dark-blue;
      padding: 30px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 100px;

      @media only screen and (max-width: $width-768) {
        min-width: 259px;
        max-width: 259px;
        height: 251px;
        border-top-right-radius: 50px;
      }

      .image-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div {
          width: 97px;
          height: 97px;
          @media only screen and (max-width: $width-768) {
            width: 56px;
            height: 56px;
          }
        }
        img {
          height: 100%;
        }
      }
      .name {
        font-family: $poppins-bold;
        font-size: 30px;
        color: #fff;
        margin-top: 30px;
        margin-bottom: 16px;
        @media only screen and (max-width: $width-768) {
          font-size: 18px;
          margin-top: 10px;
          margin-bottom: 14px;
        }
      }
      .desc {
        font-family: $poppins;
        font-size: 18px;
        line-height: 1.5;
        color: #ffebcb;
        @media only screen and (max-width: $width-768) {
          font-size: 14px;
          height: 83px;
          overflow: hidden;
        }
      }
    }
  }

  .bottom-design {
    height: 419px;
    position: absolute;
    left: 167px;
    right: 0;
    bottom: 0;
    background-color: $yellow;
    z-index: -1;
    border-bottom-left-radius: 100px;
    @media only screen and (max-width: $width-768) {
      left: 0;
      height: 238px;
    }
  }
}
