@import "../../theme.scss";

$track-height: 8px;

.minmax {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #ffffff;
  font-family: $poppins;
  font-size: 16px;
  color: #1a1a1a;
}

.slider {
  margin: 2px;
  min-height: 102px;
  background-color: white;
  padding: 27px 24px 19px;
  border-radius: 12px;
  background-color: #fff;
  
  .input-container {
    margin: auto;
    position: relative;
  }
  .rs-range {
    margin-top:20px;
    width: 100%;
    -webkit-appearance: none;

    /// focus polyfill
    &:focus {
      outline: none;
    }
    &::-moz-focus-outer {
      border: 0;
    }

    /// track
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: $track-height;
      cursor: pointer;
      box-shadow: none;
      background: $orange;
      border: 0px solid #010101;
      border-radius: 2px;
    }
    &::-moz-range-track {
      width: 100%;
      height: $track-height;
      cursor: pointer;
      box-shadow: none;
      background: $orange;
      border: 0px solid #010101;
      border-radius: 2px;
    }

    /// thumb
    &::-webkit-slider-thumb {
      box-shadow: none;
      border: 2px solid #ffffff;
      height: 22px;
      width: 22px;
      border-radius: 22px;
      background: $orange;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -7px;
    }
    &::-moz-range-thumb {
      box-shadow: none;
      border: 2px solid #ffffff;
      height: 22px;
      width: 22px;
      border-radius: 22px;
      background: $orange;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -7px;
    }
  }
}

.rs-label-container {
  position: absolute;
  top:-180%;
  transform: translateX(-33%) ;
  left: 0%;
}

.rs-label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: 58px;
  height: 58px;
  background: $orange;

  transform-origin: center center;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;

  font-family: $poppins;
  font-size: 18px;
  font-weight: bold;
  color: #fff;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: -5px;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: $orange transparent transparent transparent;
  }
}
